@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@500;800&display=swap");
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue"), url(./text/helveticaneue.ttf) format("truetype");
  font-display: swap;
}

/* Navigation */
.navbar {
  z-index: 1;
}

.border-none {
  border: none;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}

.navbar-collapse {
  padding: 0 10px;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 1px;
}

.nav-link:hover,
.nav-link:focus,
.nav-link.active
 {
  color: #00e487 !important;
}

.dropdown-menu {
  border: none;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  margin-bottom: 20px;
}

.dropdown-item {
  color: #27282a;
  padding: 10px 18px;
  text-transform: uppercase;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: black;
  background-color: #e9ecef;
}

.hd-lg {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 800;
}

.hd-sm {
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-weight: 500;
  color: #27282a;
}

/* Responsive navigation menu (for desktop devices) */
@media screen and (min-width: 993px) {
  .nav-center {
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
  }

  .nav-right {
    position: absolute;
    right: 0;
  }
}

.nav-logo {
  left: -20px;
  position: relative;
}

/* Responsive navigation menu (for mobile devices) */
@media screen and (max-width: 992px) {
  .nav-center {
    margin-top: 20px;
    position: relative;
    transform: none;
  }

  .nav-right {
    margin: 30px 0;
    position: relative;
    transform: none;
  }
}

@media screen and (min-width: 575px) {
  .nav-logo {
    left: -40px;
  }
}

.socials:hover,
.socials:focus {
  color: #f1ff53;
  cursor: pointer;
}
/* Navigation Ends */

/* Text Transform */
.uppercase {
  text-transform: uppercase;
}

.noUppercase {
  text-transform: none;
}

.noDecoration {
  text-decoration: none !important;
}
/* Text Transform Emds*/

/* Display text */
.display-table {
  display: table;
}

.display-cell {
  display: table-cell;
}

.display-vertical-align {
  display: table-cell;
  vertical-align: middle;
}

.display-inline {
  display: inline-block;
}

/* End Display text */

/* Align Content */
.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}
/* Align Content Ends */

/* Positioning */

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.r-0 {
  right: 0;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}
/* Positioning */

/* Bootstrap Edits*/
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

.col-md-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 767px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (max-width: 767px) {
  .w-100-mobile {
    width: 100%;
  }
}

/* Bootstrap Edits End */

/* Margin */

.mt-n-100 {
  margin-top: -100px;
}

.mt-n-50 {
  margin-top: -50px;
}

.m-0 {
  margin: 0;
}

.mt-5 {
  margin-top: 5px;
}

.mt-5-real {
  margin-top: 5px;
}

.mt-7 {
  margin-top: 7px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-150 {
  margin-top: 190px;
}


.mb-05 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-7 {
  margin-right: 7px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-n-5 {
  margin-left: -5px;
}

.ml-n-10 {
  margin-left: -10px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-100 {
  margin-left: 100px;
}


.mb-n-50 {
  margin-bottom: -50px;
  z-index: 1;
}

.text-bottom-left {
  position: absolute;
  bottom: 0;
}

.text-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 50px;
}

/* Specific Margin Updates */
@media (min-width: 992px) {
  .mt-box {
    margin-top: 0px;
  }
}

/* Margin Ends */

.pl-0 {
  padding-left: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.white {
  color: #fff;
}

.black {
  color: #27282a;
}

/* Character Carousel */
.mt-carousel {
  margin-top: -40px;
}

.w-carousel {
  width: 500px !important;
}

.overflow-hidden {
  overflow: hidden;
}
